//based on https://www.npmjs.com/package/angular-user-idle

import { Injectable, NgZone } from '@angular/core';
import {
  from,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  timer,
} from 'rxjs';
import {
  bufferTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Injectable()
export class IdleService {
  ping$!: Observable<any>;

  /**
   * Events that can interrupts user's inactivity timer.
   */
  protected activityEvents$!: Observable<any>;

  protected timerStart$ = new Subject<boolean>();
  protected idleDetected$ = new Subject<boolean>();
  protected timeout$ = new Subject<boolean>();
  protected idle$!: Observable<any>;
  protected timer$!: Observable<any>;
  /**
   * Idle value in milliseconds.
   * Default equals to 14:30 minutes.
   */
  protected idleMillisec = 870 * 1000;
  /**
   * Idle buffer wait time milliseconds to collect user action
   * Default equals to 1 Sec.
   */
  protected idleSensitivityMillisec = 1000;
  /**
   * Timeout value in seconds.
   * Default equals to 30 sec.
   */
  protected timeout = 30;
  /**
   * Ping value in milliseconds.
   * Default equals to 1 minutes.
   */
  protected pingMillisec = 60 * 1000;
  /**
   * Timeout status.
   */
  protected isTimeout = false;
  /**
   * Timer of user's inactivity is in progress.
   */
  protected isInactivityTimer = false;
  protected isIdleDetected = false;

  protected idleSubscription!: Subscription;

  constructor(private _ngZone: NgZone) {}

  /**
   * Start watching for user idle and setup timer and ping.
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown')
      );
    }

    this.idle$ = from(this.activityEvents$);

    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    // If any of user events is not active for idle-seconds when start timer.
    this.idleSubscription = this.idle$
      .pipe(
        bufferTime(this.idleSensitivityMillisec), // Starting point of detecting of user's inactivity
        filter(
          (arr) =>
            !arr.length && !this.isIdleDetected && !this.isInactivityTimer
        ),
        tap(() => {
          this.isIdleDetected = true;
          this.idleDetected$.next(true);
        }),
        switchMap(() =>
          this._ngZone.runOutsideAngular(() =>
            interval(1000).pipe(
              takeUntil(
                merge(
                  this.activityEvents$,
                  timer(this.idleMillisec).pipe(
                    tap(() => {
                      this.isInactivityTimer = true;
                      this.timerStart$.next(true);
                    })
                  )
                )
              ),
              finalize(() => {
                this.isIdleDetected = false;
                this.idleDetected$.next(false);
              })
            )
          )
        )
      )
      .subscribe();

    this.setupTimer(this.timeout);
    this.setupPing(this.pingMillisec);
  }

  stopWatching() {
    this.stopTimer();
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
    this.isTimeout = true;
  }

  resetTimer() {
    this.stopTimer();
    this.isTimeout = false;
  }

  /**
   * Return observable for timer's countdown number that emits after idle.
   */
  onTimerStart(): Observable<number> {
    return this.timerStart$.pipe(
      distinctUntilChanged(),
      switchMap((start) => (start ? this.timer$ : of(null)))
    );
  }

  /**
   * Return observable for idle status changed
   */
  onIdleStatusChanged(): Observable<boolean> {
    return this.idleDetected$.asObservable();
  }

  /**
   * Return observable for timeout is fired.
   */
  onTimeout(): Observable<boolean> {
    return this.timeout$.pipe(
      filter((timeout) => !!timeout),
      tap(() => (this.isTimeout = true)),
      map(() => true)
    );
  }

  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  protected setupTimer(timeout: number) {
    this._ngZone.runOutsideAngular(() => {
      this.timer$ = of(() => new Date()).pipe(
        map((fn) => fn()),
        switchMap((startDate) =>
          interval(1000).pipe(
            map(() =>
              Math.round((new Date().valueOf() - startDate.valueOf()) / 1000)
            ), //   convert elapsed count to seconds
            tap((elapsed) => {
              if (elapsed >= timeout) {
                this.timeout$.next(true);
              }
            })
          )
        )
      );
    });
  }

  /**
   * Setup ping.
   *
   * Pings every ping-seconds only if is not timeout.
   * @param pingMillisec
   */
  protected setupPing(pingMillisec: number) {
    this.ping$ = interval(pingMillisec).pipe(filter(() => !this.isTimeout));
  }
}
