import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'services/auth-service/auth.service';
import { LoginResponse } from '@app/modules/login/models/login-response';
import { BaseWebService } from '@app/core/services/base-web.service';

@Injectable({ providedIn: 'root' })
export class SSOAuthService extends BaseWebService {
  constructor(http: HttpClient) {
    super('identity/Auth', http);
  }

  GenerateJwtIfAuthenticated(): Promise<LoginResponse | null> {
    return firstValueFrom(this.postMethod('GenerateJwtIfAuthenticated', {}));
  }
  Logout() {
    return firstValueFrom(this.postMethod('Logout', {}));
  }
}

export function initSSOAuth(
  ssoAuthService: SSOAuthService,
  authService: AuthService
) {
  return () =>
    ssoAuthService.GenerateJwtIfAuthenticated().then((response) => {
      if (response != null) {
        authService.saveLoginInBrowser(response);
        authService.saveDataInBrowser(response);
      }
    });
}
